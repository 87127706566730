<template>
  <b-row>
    <b-col>
      <b-card>
        <b-card-title>
          {{ $t('Devices') }}
        </b-card-title>
        <b-card-body>
          <vs-table
            stripe
            :data="UserData.devices"
          >
            <template slot="thead">
              <vs-th>{{ $t('Browser') }}</vs-th>
              <vs-th>{{ $t('Device') }}</vs-th>
              <vs-th>{{ $t('Device Type') }}</vs-th>
              <vs-th>{{ $t('platform') }}</vs-th>
              <vs-th>{{ $t('Logged in At') }}</vs-th>
              <vs-th>{{ $t('Delete') }}</vs-th>
              <vs-th>{{ $t('Authorized') }}</vs-th>
              <vs-th>{{ $t('Authorize') }}</vs-th>
            </template>
            <!--test-->
            <template slot-scope="{data}">
              <vs-tr
                v-for="(tr, indextr) in data"
                :key="indextr"
              >
                <vs-td :data="tr.browser">
                  <img
                    v-if="tr.browser_image"
                    width="20px"
                    :src="require(`@/assets/images/browsers/${tr.browser_image}.png`)"
                  >
                </vs-td>
                <vs-td :data="tr.device">
                  {{ tr.device }}
                </vs-td>
                <vs-td :data="tr.device_type">
                  <fa-icon
                    color="primary"
                    pack="fas"
                    :icon="tr.device_type_icon"
                  />
                </vs-td>
                <vs-td :data="tr.platform">
                  <fa-icon
                    color="primary"
                    pack="fab"
                    :icon="tr.platform_icon"
                  />
                </vs-td>
                <vs-td :data="tr.created_at">
                  <VxTooltip
                    color="success"
                  >
                    <template #title>
                      {{ $t('Created_At') }}
                    </template>
                    <template #text>
                      {{ tr.created_at }}
                    </template>
                    <vx-badge
                      pack="far"
                      icon="calendar-plus"
                      color="light-success"
                      icon-color-bg="success"
                      icon-color="null"
                    >
                      {{ $t(tr.created_from) }}
                    </vx-badge>
                  </VxTooltip>
                </vs-td>
                <vs-td>
                  <a
                    class="ml-1 mr-1"
                    @click="removeAlert(tr.id)"
                  >
                    <font-awesome-icon
                      color="rgb(var(--vs-danger))"
                      :icon="['fas', 'trash-alt']"
                    />
                  </a>
                </vs-td>
                <vs-td v-if="tr.approve_date">
                  <fa-icon
                    color="success"
                    pack="far"
                    icon="check-circle"
                  />
                  {{ $t('Authorize') }}
                </vs-td>
                <vs-td v-else>
                  <fa-icon
                    color="danger"
                    pack="far"
                    icon="times-circle"
                  />
                  {{ $t('Not Authorize') }}
                </vs-td>
                <vs-td v-if="tr.approve_date">
                  <b-button
                    variant="success"
                    disabled
                  >
                    {{ $t('Authorize') }}
                  </b-button>
                </vs-td>
                <vs-td v-else>
                  <b-button
                    variant="success"
                    @click="Authorize(tr.id)"
                  >
                    {{ $t('Authorize') }}
                  </b-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      deviceID: '',
      UserData: {},
    }
  },
  computed: {
    userData() {
      return this.$store.getters['frontend/userProfile']
    },
  },
  watch: {
    userData(val) {
      this.UserData = { ...val }
    },
  },
  created() {
    this.$store.dispatch('frontend/GetUserProfile')
  },
  methods: {
    removeAlert($val) {
      this.deviceID = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this device'),
        accept: this.acceptAlert,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      })
    },
    acceptAlert() {
      this.$store.dispatch('frontend/DeleteDevice', this.deviceID)
    },
    Authorize($id) {
      this.$store.dispatch('frontend/AuthorizeDevice', $id)
    },
  },
}
</script>
